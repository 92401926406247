<template>
  <div id="ozoneDisinfection">
    <el-dialog
      :title="ozoneDisinfectionFormTitle"
      width="680px"
      :visible.sync="ozoneDisinfectionDialogVisible"
      :close-on-click-modal="false"
      @close="ozoneDisinfectionDialogClose"
    >
      <el-form
        ref="ozoneDisinfectionFormRef"
        :model="ozoneDisinfectionForm"
        :rules="ozoneDisinfectionFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="清洁消毒区域" prop="area">
              <el-input
                v-model="ozoneDisinfectionForm.area"
                placeholder="请输入清洁消毒区域"
                clearable
                :disabled="ozoneDisinfectionFormTitle !== '新增洁净区臭氧消毒记录'
                  && ozoneDisinfectionFormTitle !== '修改洁净区臭氧消毒记录'
                  && ozoneDisinfectionFormTitle !== '洁净区臭氧消毒记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="disinfectionDate">
              <el-date-picker
                v-model="ozoneDisinfectionForm.disinfectionDate"
                placeholder="请选择日期"
                value-format="yyyy-MM-dd"
                :disabled="ozoneDisinfectionFormTitle !== '新增洁净区臭氧消毒记录'
                  && ozoneDisinfectionFormTitle !== '修改洁净区臭氧消毒记录'
                  && ozoneDisinfectionFormTitle !== '洁净区臭氧消毒记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="时间" prop="disinfectionTime">
              <el-time-picker
                v-model="ozoneDisinfectionForm.disinfectionTime"
                range-separator="-"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                is-range
                value-format="HH:mm"
                :disabled="ozoneDisinfectionFormTitle !== '新增洁净区臭氧消毒记录'
                  && ozoneDisinfectionFormTitle !== '修改洁净区臭氧消毒记录'
                  && ozoneDisinfectionFormTitle !== '洁净区臭氧消毒记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="操作人" prop="operator">
              <el-input
                v-model="ozoneDisinfectionForm.operator"
                placeholder="请输入操作人"
                clearable
                :disabled="ozoneDisinfectionFormTitle !== '新增洁净区臭氧消毒记录'
                  && ozoneDisinfectionFormTitle !== '修改洁净区臭氧消毒记录'
                  && ozoneDisinfectionFormTitle !== '洁净区臭氧消毒记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="ozoneDisinfectionForm.reviewResult"
                :disabled="ozoneDisinfectionFormTitle !== '复核洁净区臭氧消毒记录'
                  && ozoneDisinfectionFormTitle !== '洁净区臭氧消毒记录详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="ozoneDisinfectionDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="ozoneDisinfectionFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="清洁消毒区域">
        <el-input v-model="searchForm.area" placeholder="请输入清洁消毒区域" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['OZONE_DISINFECTION_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="ozoneDisinfectionPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="area" label="清洁消毒区域" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.disinfectionDate">{{ scope.row.disinfectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="disinfectionTime" label="时间" />
      <el-table-column prop="operator" label="操作人" />
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['OZONE_DISINFECTION_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['OZONE_DISINFECTION_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['OZONE_DISINFECTION_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="ozoneDisinfectionPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addOzoneDisinfection,
  deleteOzoneDisinfection,
  updateOzoneDisinfection,
  selectOzoneDisinfectionInfo,
  selectOzoneDisinfectionList,
  reviewOzoneDisinfection
} from '@/api/produce/ozoneDisinfection'

export default {
  data () {
    return {
      ozoneDisinfectionDialogVisible: false,
      ozoneDisinfectionFormTitle: '',
      ozoneDisinfectionForm: {
        id: '',
        area: '',
        disinfectionDate: '',
        disinfectionTime: '',
        operator: '',
        reviewResult: '',
        status: '',
        taskId: ''
      },
      ozoneDisinfectionFormRules: {
        area: [{ required: true, message: '清洁消毒区域不能为空', trigger: ['blur', 'change']}]
      },
      ozoneDisinfectionPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        area: ''
      }
    }
  },
  created () {
    selectOzoneDisinfectionList(this.searchForm).then(res => {
      this.ozoneDisinfectionPage = res
    })
  },
  methods: {
    ozoneDisinfectionDialogClose () {
      this.$refs.ozoneDisinfectionFormRef.resetFields()
    },
    ozoneDisinfectionFormSubmit () {
      if (this.ozoneDisinfectionFormTitle === '洁净区臭氧消毒记录详情') {
        this.ozoneDisinfectionDialogVisible = false
        return
      }
      this.$refs.ozoneDisinfectionFormRef.validate(async valid => {
        if (valid) {
          this.ozoneDisinfectionForm.disinfectionTime = this.ozoneDisinfectionForm.disinfectionTime.join('-')
          if (this.ozoneDisinfectionFormTitle === '新增洁净区臭氧消毒记录') {
            this.ozoneDisinfectionForm.id = ''
            this.ozoneDisinfectionForm.status = 1
            await addOzoneDisinfection(this.ozoneDisinfectionForm)
          } else if (this.ozoneDisinfectionFormTitle === '修改洁净区臭氧消毒记录') {
            await updateOzoneDisinfection(this.ozoneDisinfectionForm)
          } else if (this.ozoneDisinfectionFormTitle === '复核洁净区臭氧消毒记录') {
            this.ozoneDisinfectionForm.status = 2
            await reviewOzoneDisinfection(this.ozoneDisinfectionForm)
          }
          this.ozoneDisinfectionPage = await selectOzoneDisinfectionList(this.searchForm)
          this.ozoneDisinfectionDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.ozoneDisinfectionFormTitle = '新增洁净区臭氧消毒记录'
      this.ozoneDisinfectionDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteOzoneDisinfection(row.id)
        if (this.ozoneDisinfectionPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.ozoneDisinfectionPage = await selectOzoneDisinfectionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.ozoneDisinfectionFormTitle = '修改洁净区臭氧消毒记录'
      this.ozoneDisinfectionDialogVisible = true
      this.selectOzoneDisinfectionInfoById(row.id)
    },
    handleReview (index, row) {
      this.ozoneDisinfectionFormTitle = '复核洁净区臭氧消毒记录'
      this.ozoneDisinfectionDialogVisible = true
      this.selectOzoneDisinfectionInfoById(row.id)
    },
    handleInfo (index, row) {
      this.ozoneDisinfectionFormTitle = '洁净区臭氧消毒记录详情'
      this.ozoneDisinfectionDialogVisible = true
      this.selectOzoneDisinfectionInfoById(row.id)
    },
    selectOzoneDisinfectionInfoById (id) {
      selectOzoneDisinfectionInfo(id).then(res => {
        this.ozoneDisinfectionForm.id = res.id
        this.ozoneDisinfectionForm.area = res.area
        this.ozoneDisinfectionForm.disinfectionDate = res.disinfectionDate
        this.ozoneDisinfectionForm.disinfectionTime = res.disinfectionTime.split('-')
        this.ozoneDisinfectionForm.operator = res.operator
        this.ozoneDisinfectionForm.reviewResult = res.reviewResult
        this.ozoneDisinfectionForm.status = res.status
        this.ozoneDisinfectionForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectOzoneDisinfectionList(this.searchForm).then(res => {
        this.ozoneDisinfectionPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectOzoneDisinfectionList(this.searchForm).then(res => {
        this.ozoneDisinfectionPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectOzoneDisinfectionList(this.searchForm).then(res => {
        this.ozoneDisinfectionPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['OZONE_DISINFECTION_REVIEW']) && row.status === 1) {
        return 'review'
      }
      return ''
    }
  }
}
</script>

<style>
</style>
