import axios from '@/common/axios'
import qs from 'qs'

export function addOzoneDisinfection (data) {
  return axios({
    method: 'post',
    url: '/production/ozoneDisinfection/add',
    data: qs.stringify(data)
  })
}

export function deleteOzoneDisinfection (id) {
  return axios({
    method: 'delete',
    url: '/production/ozoneDisinfection/delete/' + id
  })
}

export function updateOzoneDisinfection (data) {
  return axios({
    method: 'put',
    url: '/production/ozoneDisinfection/update',
    data: qs.stringify(data)
  })
}

export function selectOzoneDisinfectionInfo (id) {
  return axios({
    method: 'get',
    url: '/production/ozoneDisinfection/info/' + id
  })
}

export function selectOzoneDisinfectionList (query) {
  return axios({
    method: 'get',
    url: '/production/ozoneDisinfection/list',
    params: query
  })
}

export function reviewOzoneDisinfection (data) {
  return axios({
    method: 'put',
    url: '/production/ozoneDisinfection/updateReview',
    data: qs.stringify(data)
  })
}
